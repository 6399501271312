import React from 'react';
import { AlertContext } from './Alert';
import classnames from 'classnames';
import { HTMLPropsWithoutAs } from '../utils/types';

export interface AlertHeaderProps {
  /**
   * The element or component the Alert.Header should render as.
   */
  as?: React.ElementType<any> | keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
  className?: string;
}

export const AlertHeader = ({
  as: Component = 'div',
  children,
  className,
  ...props
}: AlertHeaderProps & HTMLPropsWithoutAs) => {
  const { variant } = React.useContext(AlertContext);

  return (
    <Component
      className={classnames(
        'bmspt-text-sm bmspt-font-medium',
        {
          'bmspt-text-red-800': variant === 'error',
          'bmspt-text-yellow-800': variant === 'warning',
          'bmspt-text-blue-800': variant === 'info',
          'bmspt-text-green-800': variant === 'success',
        },
        'bcl-alert__header',
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

AlertHeader.displayName = 'Alert.Header';
