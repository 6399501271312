import React from 'react';
import classnames from 'classnames';

export interface DataCellProps {
  children?: React.ReactNode;
  className?: string;
}

export const DataCell = ({
  children,
  className,
  ...props
}: DataCellProps & React.HTMLProps<HTMLTableCellElement>) => (
  <td
    className={classnames(
      'bmspt-px-6 bmspt-py-4 bmspt-whitespace-nowrap bmspt-text-sm bmspt-font-medium bmspt-text-gray-900 bmspt-text-left bcl-table__td',
      className
    )}
    {...props}
  >
    {children}
  </td>
);

DataCell.displayName = 'Table.TD';
