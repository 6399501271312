import React from 'react';
import classnames from 'classnames';
import { HTMLPropsWithoutAs } from '../utils/types';

export interface ToastHeaderProps {
  /**
   * The element or component the Toast.Header should render as.
   */
  as?: React.ElementType<any> | keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
  className?: string;
}

export const ToastHeader = ({
  as: Component = 'p',
  children,
  className,
  ...props
}: ToastHeaderProps & HTMLPropsWithoutAs) => (
  <Component
    className={classnames(
      'bmspt-text-sm bmspt-font-medium bmspt-text-gray-900',
      'bcl-toast__header',
      className
    )}
    {...props}
  >
    {children}
  </Component>
);

ToastHeader.displayName = 'Toast.Header';
