import React from 'react';
import { ExclamationIcon } from '@heroicons/react/solid';
import { XCircleIcon } from '@heroicons/react/solid';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { AlertProps } from '../Alert';

export const renderIcon = (variant: AlertProps['variant']) => {
  switch (variant) {
    case 'error':
      return (
        <XCircleIcon
          className="bmspt-h-5 bmspt-w-5 bmspt-text-red-400"
          aria-hidden="true"
        />
      );
    case 'warning':
      return (
        <ExclamationIcon
          className="bmspt-h-5 bmspt-w-5 bmspt-text-yellow-400"
          aria-hidden="true"
        />
      );
    case 'info':
      return (
        <InformationCircleIcon
          className="bmspt-h-5 bmspt-w-5 bmspt-text-blue-400"
          aria-hidden="true"
        />
      );
    case 'success':
      return (
        <CheckCircleIcon
          className="bmspt-h-5 bmspt-w-5 bmspt-text-green-400"
          aria-hidden="true"
        />
      );
    default:
      return null;
  }
};
