import React from 'react';
import { AlertContext } from './Alert';
import classnames from 'classnames';

export interface AlertDescriptionProps {
  children?: React.ReactNode;
  className?: string;
}

export const AlertDescription = ({
  children,
  className,
  ...props
}: AlertDescriptionProps & React.HTMLProps<HTMLDivElement>) => {
  const { variant } = React.useContext(AlertContext);

  return (
    <div
      className={classnames(
        'bmspt-mt-2 bmspt-text-sm',
        {
          'bmspt-text-red-700': variant === 'error',
          'bmspt-text-yellow-700': variant === 'warning',
          'bmspt-text-blue-700': variant === 'info',
          'bmspt-text-green-700': variant === 'success',
        },
        'bcl-alert__description',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

AlertDescription.displayName = 'Alert.Description';
