import React from 'react';
import classnames from 'classnames';
import { HTMLPropsWithoutAs } from '../utils/types';

export interface CardBodyProps {
  children?: React.ReactNode;
  className?: string;
}

export const CardBody = ({
  children,
  className,
  ...props
}: CardBodyProps & HTMLPropsWithoutAs) => (
  <div
    className={classnames('bmspt-text-md bcl-card__body', className)}
    {...props}
  >
    {children}
  </div>
);

CardBody.displayName = 'Card.Body';
