import React from 'react';
import classnames from 'classnames';

export interface SpinnerProps {
  /**
   * Renders the Spinner with different colour.
   */
  variant?: 'primary' | 'light';
  /**
   * Renders the Spinner in the specified size.
   */
  size?: 'normal' | 'small';

  className?: string;
}

/**
 * A unified button component
 */
export const Spinner = ({
  variant = 'primary',
  size = 'normal',
  className,
  ...props
}: SpinnerProps) => {
  return (
    <div
      className={classnames(
        'bmspt-animate-spin bmspt-rounded-full bmspt-border-b-2',
        {
          'bmspt-border-bs-blue': variant === 'primary',
          'bmspt-border-gray-200': variant === 'light',
          'bmspt-h-8 bmspt-w-8': size === 'normal',
          'bmspt-h-4 bmspt-w-4': size === 'small',
        },
        'bcl-spinner',
        className
      )}
      {...props}
    />
  );
};
