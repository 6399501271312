import React from 'react';
import classnames from 'classnames';

export interface ToastRegionProps {
  children?: React.ReactNode;
  ariaLiveSetting?: React.AriaAttributes['aria-live'];
  className?: string;
}

export const ToastRegion = ({
  children,
  className,
  ariaLiveSetting = 'polite',
  ...props
}: ToastRegionProps & React.HTMLProps<HTMLDivElement>) => {
  return (
    <div
      aria-live={ariaLiveSetting}
      className={classnames(
        'bmspt-fixed bmspt-inset-0',
        'bmspt-flex bmspt-flex-col',
        'bmspt-space-y-8',
        'bmspt-px-4 bmspt-py-6 sm:bmspt-p-6',
        'bmspt-pointer-events-none',
        'bmspt-z-[9999]',
        'bcl-toast__region',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

ToastRegion.displayName = 'ToastRegion';
