import React from 'react';
import classnames from 'classnames';
import { HTMLPropsWithoutAs } from '../utils/types';

export interface ToastDescriptionProps {
  /**
   * The element or component the Toast.Description should render as.
   */
  as?: React.ElementType<any> | keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
  className?: string;
}

export const ToastDescription = ({
  as: Component = 'p',
  children,
  className,
  ...props
}: ToastDescriptionProps & HTMLPropsWithoutAs) => (
  <Component
    className={classnames(
      'bmspt-mt-1 bmspt-text-sm bmspt-text-gray-500',
      'bcl-toast__description',
      className
    )}
    {...props}
  >
    {children}
  </Component>
);

ToastDescription.displayName = 'Toast.Description';
