import classnames from 'classnames';
import { HTMLProps } from 'react';

export const DropdownDivider = ({
  className,
  ...props
}: HTMLProps<HTMLDivElement>) => (
  <div
    className={classnames(
      'bmspt-h-0 bmspt-border-t bmspt-border-gray-200 bmspt-my-2',
      'bmspt-mx-0 bmspt-overflow-hidden',
      'bcl-dropdown__divider',
      className
    )}
    role="separator"
    {...props}
  ></div>
);

DropdownDivider.displayName = 'Dropdown.Divider';
