import React, { useState } from 'react';
import classnames from 'classnames';
import { Menu } from '@headlessui/react';
import { DropdownToggle } from './DropdownToggle';
import { DropdownItems } from './DropdownItems';
import { DropdownItem } from './DropdownItem';
import { DropdownDivider } from './DropdownDivider';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';

export interface DropdownProps {
  children?: React.ReactNode;
  /**
   * Given enough space in the viewport, the dropdown items will try to open
   * below and to the left of the toggle element (corresponding to a
   * "bottom-start" value). This preference can be overwritten with this prop.
   * Note, however, that this is merely a preference and that popper will always
   * try to place the items in such a way that they fit into the viewport.
   */
  preferredPlacement?: Placement;
}

interface DropdownContextInterface {
  isMenuOpen: boolean;
  setReferenceElement: React.Dispatch<React.SetStateAction<any>>;
  setPopperElement: React.Dispatch<React.SetStateAction<any>>;
  styles: { [key: string]: React.CSSProperties };
  attributes: any;
  update: any;
}

export const DropdownContext = React.createContext({
  isMenuOpen: false,
} as DropdownContextInterface);
DropdownContext.displayName = 'DropdownContext';

/**
 * Accessible Dropdown component, including correct ARIA attribute management
 * and robust keyboard navigation support.
 */
export const Dropdown = ({
  className,
  preferredPlacement = 'bottom-start',
  children,
  ...props
}: DropdownProps & React.HTMLProps<HTMLDivElement>) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      strategy: 'fixed',
      placement: preferredPlacement,
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    }
  );

  return (
    <div
      className={classnames(
        'bmspt-relative bmspt-inline-block bmspt-text-left bmspt-z-1 bcl-dropdown',
        className
      )}
      {...props}
    >
      <Menu>
        {({ open }) => (
          <DropdownContext.Provider
            value={{
              isMenuOpen: open,
              setReferenceElement,
              setPopperElement,
              styles,
              attributes,
              update,
            }}
          >
            {children}
          </DropdownContext.Provider>
        )}
      </Menu>
    </div>
  );
};

/**
 * Dropdown.Toggle is the first child of Dropdown.
 * It is a button element that opens/closes Dropdown.Items.
 */
Dropdown.Toggle = DropdownToggle;
/**
 * A wrapper component which houses the individual Dropdown.Item components.
 * It is wrapped with a Transition component to provide animations when
 * opening or closing the dropdown.
 */
Dropdown.Items = DropdownItems;
/**
 * Individual dropdown item. Renders as 'button' by default but
 * can be overwritten.
 */
Dropdown.Item = DropdownItem;
/**
 * A purely decorative element to help separate/group logically related items
 * in a list of dropdown items.
 */
Dropdown.Divider = DropdownDivider;

Dropdown.displayName = 'Dropdown';
