import classnames from 'classnames';
import React from 'react';
import { AlertDescription } from './AlertDescription';
import { AlertHeader } from './AlertHeader';
import { renderIcon } from './utils/renderIcon';

export interface AlertProps {
  children?: React.ReactNode;
  /**
   * Renders the alert with semantically meaningful colours.
   */
  variant?: 'error' | 'warning' | 'info' | 'success';
  /**
   * Renders the alert with an icon.
   * The icon used depends on the semantic variant.
   */
  withIcon?: boolean;
  className?: string;
}

export const AlertContext = React.createContext({
  variant: '',
});
AlertContext.displayName = 'AlertContext';

/**
 * The Alert component is useful to signal errors, warnings, successful actions
 * as well as other, more general information to the user.
 * It does so in the form of a visually distinct box with semantically
 * meaningful colours and icons.
 */
export const Alert = ({
  children,
  className,
  variant = 'info',
  withIcon = true,
  ...props
}: AlertProps & React.HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className={classnames(
        'bmspt-rounded-md bmspt-p-4',
        {
          'bmspt-bg-red-50': variant === 'error',
          'bmspt-bg-yellow-50': variant === 'warning',
          'bmspt-bg-blue-50': variant === 'info',
          'bmspt-bg-green-50': variant === 'success',
        },
        'bcl-alert',
        className
      )}
      {...props}
    >
      <div className="bmspt-flex bcl-alert__content-wrapper">
        {withIcon && (
          <div className="bmspt-flex-shrink-0 bcl-alert__icon">
            {renderIcon(variant)}
          </div>
        )}
        <div
          className={classnames('bcl-alert__content', {
            'bmspt-ml-3': withIcon,
          })}
        >
          <AlertContext.Provider value={{ variant }}>
            {children}
          </AlertContext.Provider>
        </div>
      </div>
    </div>
  );
};

/**
 * The alert header. This should always be present in an Alert component.
 */
Alert.Header = AlertHeader;
/**
 * An optional description for the alert.
 */
Alert.Description = AlertDescription;

Alert.displayName = 'Alert';
