import React from 'react';
import { Item, SortedBy } from './Table';
import {
  ChevronDoubleUpIcon,
  ChevronDoubleDownIcon,
} from '@heroicons/react/solid';
import classnames from 'classnames';

const iconClasses =
  'bmspt-w-4 bmspt-h-4 bmspt-fill-current bmspt-text-gray-700 bmspt-ml-2';

export interface HeaderCellProps<T> {
  propName?: keyof Item<T>;
  sortable?: boolean;
  children?: React.ReactNode;
  sortedBy?: SortedBy<T>;
  setSortedBy?: React.Dispatch<React.SetStateAction<SortedBy<T> | undefined>>;
  className?: string;
}

export const HeaderCell = <T,>({
  children,
  propName,
  sortable,
  sortedBy,
  setSortedBy,
  className,
  ...props
}: HeaderCellProps<T> & React.HTMLProps<HTMLTableCellElement>) => {
  const active = propName && sortedBy?.propName === propName;
  const isDesc = sortedBy?.order === 'DESC';

  const handleSort = () => {
    if (propName && sortable) {
      setSortedBy?.(
        active
          ? { propName, order: isDesc ? 'ASC' : 'DESC' }
          : { order: 'ASC', propName }
      );
    }
  };

  const handleOnKeyDown = (evt: React.KeyboardEvent) => {
    if ([' ', 'Enter'].includes(evt.key)) {
      evt.preventDefault();
      handleSort();
    }
  };

  const sortableProps = sortable ? { role: 'button', tabIndex: 0 } : {};

  return (
    <th
      className={classnames(
        'bmspt-px-6 bmspt-py-3',
        'bmspt-text-left bmspt-text-xs bmspt-text-gray-500',
        'bmspt-font-medium bmspt-uppercase bmspt-tracking-wider',
        'bmspt-whitespace-pre-line',
        'bmspt-border-b bmspt-border-transparent',
        'focus:bmspt-outline-none focus-visible:bmspt-bg-gray-100 focus-visible:bmspt-border-gray-700',
        'bmspt-align-bottom bcl-table__th',
        {
          'bmspt-underline': active,
          'bmspt-cursor-pointer': sortable,
        },
        className
      )}
      onClick={handleSort}
      onKeyDown={handleOnKeyDown}
      {...sortableProps}
      {...props}
    >
      <div
        className={classnames('bmspt-flex bmspt-items-center', {
          'bmspt-mr-6': !active && sortable,
        })}
      >
        {children}
        {active &&
          (isDesc ? (
            <ChevronDoubleDownIcon className={iconClasses} />
          ) : (
            <ChevronDoubleUpIcon className={iconClasses} />
          ))}
      </div>
    </th>
  );
};

HeaderCell.displayName = 'Table.TH';
