import React from 'react';
import classnames from 'classnames';

export interface THeadProps {
  children: React.ReactNode;
  className?: string;
}

export const THead = ({
  children,
  className,
  ...props
}: THeadProps & React.HTMLProps<HTMLTableSectionElement>) => (
  <thead
    className={classnames('bmspt-bg-gray-50 bcl-table__thead', className)}
    {...props}
  >
    {children}
  </thead>
);

THead.displayName = 'Table.Head';
