import classnames from 'classnames';

const getClasses = (variant: string, disabled: boolean | undefined): string => {
  switch (variant) {
    case 'primary':
      return classnames(
        'bmspt-text-white bmspt-bg-bs-blue bmspt-border-transparent',
        'hover:bmspt-bg-bs-dark-blue focus:bmspt-ring-bs-blue',
        { 'disabled:bmspt-bg-bs-blue hover:bmspt-bg-bs-blue': disabled }
      );
    case 'secondary':
      return classnames(
        'bmspt-text-black bmspt-bg-gray-100 bmspt-border-transparent',
        'hover:bmspt-bg-gray-300 focus:bmspt-ring-bs-blue',
        { 'disabled:bmspt-bg-gray-100 hover:bmspt-bg-gray-100': disabled }
      );
    case 'error':
      return classnames(
        'bmspt-text-red-700 bmspt-bg-red-50 bmspt-border-transparent',
        'hover:bmspt-bg-red-200 focus:bmspt-ring-red-500',
        { 'disabled:bmspt-bg-red-50 hover:bmspt-bg-red-50': disabled }
      );
    case 'outline':
      return classnames(
        'bmspt-border-gray-300 bmspt-text-gray-700 bmspt-bg-transparent',
        'hover:bmspt-bg-gray-50 focus:bmspt-ring-bs-blue',
        { 'disabled:bmspt-bg-transparent hover:bmspt-bg-transparent': disabled }
      );
  }

  return '';
};

export const getButtonClasses = (
  variant: string,
  size: string,
  disabled: boolean | undefined
) =>
  classnames(
    'bmspt-inline-flex bmspt-items-center bmspt-justify-center bmspt-px-4 bmspt-py-2 bmspt-border bmspt-font-medium bmspt-rounded-md bmspt-shadow-sm',
    'focus:bmspt-outline-none focus:bmspt-ring-2 focus:bmspt-ring-offset-2',
    'disabled:bmspt-cursor-not-allowed disabled:bmspt-opacity-50',
    {
      'bmspt-text-sm': size === 'normal',
      'bmspt-text-base': size === 'large',
      'bmspt-cursor-pointer': !disabled,
    },
    getClasses(variant, disabled)
  );
