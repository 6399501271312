import React from 'react';
import classnames from 'classnames';

import { getButtonClasses } from './utils/buttonStylingClasses';

export interface ButtonLinkProps {
  /**
   * The element or component the ButtonLink should be rendered as.
   */
  as?: React.ElementType<any> | keyof JSX.IntrinsicElements;
  /**
   * Renders the link with meaningful styling.
   */
  variant?: 'primary' | 'secondary' | 'error' | 'outline';
  /**
   * Renders the link in the specified size.
   */
  size?: 'normal' | 'large';
  /**
   * Specifies the url or path the link redirects to.
   */
  href?: string;
  to?: string;
  /**
   * Link contents
   */
  children: string | React.ReactNode;
  disabled?: boolean;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  className?: string;
}

/**
 * A link component to match button styling
 */
export const ButtonLink = ({
  as: Component = 'a',
  variant = 'primary',
  size = 'normal',
  children,
  disabled,
  className,
  ...props
}: ButtonLinkProps) => {
  return (
    <Component
      className={classnames(
        getButtonClasses(variant, size, disabled),
        { 'bmspt-pointer-events-none bmspt-opacity-50': disabled },
        'bcl-buttonLink',
        className
      )}
      rel="noopener noreferrer"
      disabled={disabled}
      {...props}
    >
      {children}
    </Component>
  );
};
