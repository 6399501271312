import React from 'react';
import { displayDate, displayExactDate } from './formatDate';
import { formatNumWithSymbol } from './formatNumber';

export const renderTypeWithSaneDefaults = (
  value: any
): string | JSX.Element | null => {
  try {
    switch (value.constructor.name) {
      case 'String':
        return value;
      case 'Number':
        return <span title={value}>{formatNumWithSymbol(value)}</span>;
      case 'Date':
        return (
          <span title={displayExactDate(value)}>{displayDate(value)}</span>
        );
      default:
        return null;
    }
  } catch {
    return null;
  }
};
