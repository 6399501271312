import React from 'react';
import classnames from 'classnames';

export interface TableContainerProps {
  children?: React.ReactNode;
  className?: string;
}

export const TableContainer = ({
  children,
  className,
  ...props
}: TableContainerProps & React.HTMLProps<HTMLDivElement>) => (
  <div
    className={classnames(
      'bmspt-overflow-hidden bmspt-border-b bmspt-border-gray-200 bmspt-shadow bmspt-rounded-md bcl-table__container',
      className
    )}
    {...props}
  >
    {children}
  </div>
);

TableContainer.displayName = 'Table.Container';
