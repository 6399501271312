import React from 'react';
import classnames from 'classnames';

import { getButtonClasses } from './utils/buttonStylingClasses';

export interface ButtonProps {
  /**
   * Renders the button with meaningful styling.
   */
  variant?: 'primary' | 'secondary' | 'error' | 'outline';
  /**
   * Renders the button in the specified size.
   */
  size?: 'normal' | 'large';
  type?: 'button' | 'submit' | 'reset';
  /**
   * Button contents
   */
  children: string | React.ReactNode;
  disabled?: boolean;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  className?: string;
}

/**
 * A unified button component
 */
export const Button = ({
  variant = 'primary',
  size = 'normal',
  type = 'button',
  children,
  disabled,
  className,
  ...props
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={classnames(
        getButtonClasses(variant, size, disabled),
        'bcl-button',
        className
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
