import React, { useContext } from 'react';
import { Menu } from '@headlessui/react';
import classnames from 'classnames';
import { DropdownContext } from './Dropdown';
import { ChevronDownIcon } from '@heroicons/react/solid';

export interface DropdownToggleProps {
  children?: React.ReactNode;
  /**
   * Specifies a default styling; "text" is appropriate for text based toggles
   * whereas "icon" is suitable for ellipsis or avatar type dropdowns.
   */
  toggleType?: 'text' | 'icon';
  /**
   * Renders the toggle element with a border around it.
   */
  withBorder?: boolean;
  /**
   * Renders a caret (downward pointing arrow) next to the toggle element's
   * content.
   */
  withCaret?: boolean;
  className?: string;
}

export const DropdownToggle = ({
  children,
  className,
  toggleType = 'text',
  withBorder = false,
  withCaret = toggleType === 'text',
  ...props
}: DropdownToggleProps) => {
  const toggleStyles = {
    text: classnames(
      'bmspt-inline-flex bmspt-justify-center bmspt-w-full bmspt-p-1 bmspt-text-sm bmspt-font-medium bmspt-text-gray-700 bmspt-bg-white bmspt-rounded-md',
      'focus:bmspt-outline-none focus-visible:bmspt-ring-2 focus-visible:bmspt-ring-offset-2 focus-visible:bmspt-ring-offset-gray-100 focus-visible:bmspt-ring-indigo-500',
      {
        'bmspt-border bmspt-border-gray-300 bmspt-shadow-sm bmspt-px-4 bmspt-py-2 hover:bmspt-bg-gray-50 dark:bmspt-bg-gray-700 dark:hover:bmspt-bg-gray-800': withBorder,
      },
      { 'dark:bmspt-bg-transparent': !withBorder },
      'dark:bmspt-text-gray-100'
    ),

    icon: classnames(
      'bmspt-w-8 bmspt-h-8',
      'bmspt-flex bmspt-items-center bmspt-justify-center bmspt-text-gray-400 bmspt-rounded-full',
      'hover:bmspt-text-gray-700',
      'focus:bmspt-outline-none focus:bmspt-text-gray-700 focus-visible:bmspt-ring focus-visible:bmspt-ring-offset-2 focus-visible:bmspt-ring-indigo-500',
      'dark:bmspt-text-gray-100 dark:bmspt-border-gray-100 dark:hover:bmspt-text-gray-100 dark:focus:bmspt-text-gray-100',
      {
        'bmspt-border bmspt-border-gray-300 bmspt-rounded-md': withBorder,
      },
      'focus:bmspt-outline-none focus:bmspt-text-gray-700 focus-visible:bmspt-ring focus-visible:bmspt-ring-offset-2 focus-visible:bmspt-ring-indigo-500',
      {
        'bmspt-border bmspt-border-gray-300 bmspt-rounded-md': withBorder,
      }
    ),
  };

  const { setReferenceElement } = useContext(DropdownContext);

  return (
    <Menu.Button
      className={classnames(
        toggleStyles[toggleType],
        'bcl-dropdown__toggle',
        className
      )}
      ref={setReferenceElement}
      {...props}
    >
      {children}
      {withCaret ? (
        <ChevronDownIcon className="bmspt-self-end bmspt-w-5 bmspt-h-5 bmspt-ml-2 bmspt--mr-1" />
      ) : null}
    </Menu.Button>
  );
};

DropdownToggle.displayName = 'Dropdown.Toggle';
