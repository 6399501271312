import React from 'react';
import { Menu } from '@headlessui/react';
import classnames from 'classnames';
import { HTMLPropsWithoutAs } from '../utils/types';

export interface DropdownItemProps {
  children?: React.ReactNode;
  /**
   * The element or component the Dropdown.Item should render as.
   */
  as?: React.ElementType<any> | keyof JSX.IntrinsicElements;
  /**
   * Whether or not the item should be disabled for keyboard navigation
   * and ARIA purposes.
   */
  disabled?: boolean;
}

export const DropdownItem = ({
  as: Component = 'button',
  className,
  disabled = false,
  children,
  ...props
}: DropdownItemProps & HTMLPropsWithoutAs) => (
  <Menu.Item disabled={disabled}>
    {({ active }) => (
      <Component
        className={classnames(
          'bmspt-flex bmspt-whitespace-nowrap bmspt-justify-between bmspt-w-full bmspt-px-4 bmspt-py-2 bmspt-text-sm bmspt-leading-5 bmspt-text-left focus:bmspt-outline-none focus-visible:bmspt-ring-2 focus-visible:bmspt-ring-offset-2 focus-visible:bmspt-ring-offset-gray-100 focus-visible:bmspt-ring-indigo-500',
          'dark:hover:bmspt-bg-gray-800 dark:bmspt-text-gray-100',
          {
            'bmspt-bg-gray-100 bmspt-text-gray-900': active,
            'bmspt-text-gray-700': !active,
            'bmspt-cursor-not-allowed bmspt-opacity-50': disabled,
          },
          'bcl-dropdown__item',
          className
        )}
        {...props}
      >
        <span className="bmspt-truncate bmspt-w-full bcl-dropdown__item-inner">
          {children}
        </span>
      </Component>
    )}
  </Menu.Item>
);

DropdownItem.displayName = 'Dropdown.Item';
