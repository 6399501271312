import React from 'react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { XCircleIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { ToastProps } from '../Toast';

export const renderIcon = (icon: ToastProps['icon']) => {
  switch (icon) {
    case 'error':
      return (
        <XCircleIcon
          className="bmspt-h-6 bmspt-w-6 bmspt-text-red-400"
          aria-hidden="true"
        />
      );
    case 'warning':
      return (
        <ExclamationIcon
          className="bmspt-h-6 bmspt-w-6 bmspt-text-yellow-400"
          aria-hidden="true"
        />
      );
    case 'info':
      return (
        <InformationCircleIcon
          className="bmspt-h-6 bmspt-w-6 bmspt-text-blue-400"
          aria-hidden="true"
        />
      );
    case 'success':
      return (
        <CheckCircleIcon
          className="bmspt-h-6 bmspt-w-6 bmspt-text-green-400"
          aria-hidden="true"
        />
      );
    default:
      return icon;
  }
};
