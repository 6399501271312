import React from 'react';
import classnames from 'classnames';

export interface RowProps {
  children?: React.ReactNode;
  className?: string;
}

export const Row = ({
  children,
  className,
  ...props
}: RowProps & React.HTMLProps<HTMLTableRowElement>) => (
  <tr className={classnames('bcl-table__tr', className)} {...props}>
    {children}
  </tr>
);

Row.displayName = 'Table.Row';
