import React from 'react';
import classnames from 'classnames';
import { CardHeader } from './CardHeader';
import { CardBody } from './CardBody';
import { HTMLPropsWithoutAs } from '../utils/types';

export interface CardProps {
  /**
   * The element or component the Card should be rendered as.
   */
  as?: React.ElementType<any> | keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
  /**
   * Inverts the colours of the card.
   */
  inverse?: boolean;
  /**
   * Adds animating on hover to the card.
   */
  interactive?: boolean;
  className?: string;
}

const interactiveClasses = `
  bmspt-transition 
  hover:bmspt-transform focus:bmspt-transform 
  hover:bmspt--translate-y-1 focus:bmspt--translate-y-1
  hover:bmspt-shadow-lg focus:bmspt-shadow-lg active:bmspt-shadow-lg 
  hover:bmspt-no-underline focus:bmspt-no-underline active:bmspt-no-underline
  hover:bmspt-cursor-pointer
`;

/**
 * The Card component is a simple wrapper to style card like
 * elements.
 */
export const Card = ({
  as: Component = 'div',
  children,
  inverse,
  interactive,
  className,
  ...props
}: CardProps & HTMLPropsWithoutAs) => (
  <Component
    className={classnames(
      'bmspt-block bmspt-bg-white bmspt-overflow-hidden bmspt-shadow bmspt-rounded-md',
      {
        'bmspt-bg-gray-700 bmspt-text-white': inverse,
        [interactiveClasses]: interactive,
      },
      'bcl-card',
      className
    )}
    {...props}
  >
    <div className="bmspt-px-4 bmspt-py-5 sm:bmspt-p-6 bcl-card__content-wrapper">
      {children}
    </div>
  </Component>
);

/**
 * Optional unified Header and Body for the Card component.
 */
Card.Header = CardHeader;
Card.Body = CardBody;

Card.displayName = 'Card';
