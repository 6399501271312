import React from 'react';
import classnames from 'classnames';
import { HTMLPropsWithoutAs } from '../utils/types';

export interface CardHeaderProps {
  /**
   * The element or component the Card.Header should render as.
   */
  as?: React.ElementType<any> | keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
  className?: string;
}

export const CardHeader = ({
  as: Component = 'div',
  children,
  className,
  ...props
}: CardHeaderProps & HTMLPropsWithoutAs) => (
  <Component
    className={classnames(
      'bmspt-text-2xl bmspt-font-bold bcl-card__header',
      className
    )}
    {...props}
  >
    {children}
  </Component>
);

CardHeader.displayName = 'Card.Header';
