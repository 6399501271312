import React, { useState, useMemo } from 'react';
import { DataCell } from './DataCell';
import { HeaderCell } from './HeaderCell';
import { Row } from './Row';
import { TableContainer } from './TableContainer';
import { TBody } from './TBody';
import { THead } from './THead';
import { renderTypeWithSaneDefaults } from './utils/defaultRender';
import classnames from 'classnames';

export type Item<T> = ({ id: string } | { _id: string }) & T;

interface Column<T> {
  /**
   * The title which will be rendered in the column header.
   */
  title: string;
  /**
   * A unique identifier. A key from an item object connecting the item's "cell"
   * to the appropriate header column.
   */
  propName: keyof Item<T>;
  /**
   * By default, the table renders with smart default for simple values.
   * With the render function, however, we can supply completely custom styles
   * and return custom JSX for the individual cells.
   * Also useful for putting together composite values.
   */
  render?: (item: Item<T>) => JSX.Element | null;
  sortable?: boolean;
  /**
   * Specifies how many columns the header cell should extend. Given a value n,
   * the next n - 1 number of headers won't be rendered.
   */
  colSpan?: number;
}

export interface SortedBy<T> {
  /**
   * Specifies the column to sort by.
   */
  propName: keyof Item<T>;
  /**
   * Specifies the sorting order.
   */
  order: 'ASC' | 'DESC';
}

export interface TableData<T> {
  /**
   * The rows of a table.
   * See {@link Item} for more information.
   */
  items: Item<T>[];
  /**
   * The columns of a table.
   * See {@link Column} for more information.
   */
  columns: Column<T>[];
  /**
   * Specifies which column to sort by and in which order (ASC vs DESC).
   * See {@link SortedBy} for more information.
   */
  sortedBy?: SortedBy<T>;
  setSortedBy?: React.Dispatch<React.SetStateAction<SortedBy<T> | undefined>>;
}

export interface TableProps<T> {
  /**
   * Renders the table with borders.
   */
  bordered?: boolean;
  /**
   * Those zebra stripes, hmmmm
   */
  striped?: boolean;
  /**
   * Generates a table programatically from the object passed in here.
   * See {@link TableData} for more information.
   */
  tableData?: TableData<T>;
  className?: string;
  children?: React.ReactNode;
}

export const TableContext = React.createContext({
  bordered: false,
  striped: false,
});
TableContext.displayName = 'TableContext';

const isOdd = (n: number) => n % 2;

const constructTableFromData = <T,>(
  tableData: TableData<T>,
  bordered: boolean,
  striped: boolean,
  sortedBy?: SortedBy<T>,
  setSortedBy?: React.Dispatch<React.SetStateAction<SortedBy<T> | undefined>>,
  sortedItems?: Item<T>[]
) => {
  const { columns } = tableData;
  let colEnd = 0;
  return (
    <>
      <Table.Head>
        <Table.Row>
          {columns.map(({ title, propName, sortable, colSpan }, idx) => {
            const shouldRender = idx >= colEnd;
            if (colSpan) {
              colEnd = colSpan + idx;
            }

            const sortedByProps = sortedBy ? { sortedBy } : {};
            const setSortedByProps = setSortedBy ? { setSortedBy } : {};
            const colspanProps = colSpan ? { colSpan } : {};

            const thProps = {
              ...sortedByProps,
              ...setSortedByProps,
              ...colspanProps,
            };

            return (
              shouldRender && (
                <Table.TH
                  key={propName as string}
                  propName={propName}
                  sortable={!!sortable}
                  {...thProps}
                >
                  {title}
                </Table.TH>
              )
            );
          })}
        </Table.Row>
      </Table.Head>
      <tbody
        className={classnames('bcl-table__tbody', {
          'bmspt-bg-white bmspt-divide-gray-200 bmspt-divide-y': bordered,
        })}
      >
        {sortedItems?.map((item, idx) => (
          <Table.Row
            key={'id' in item ? item.id : item._id}
            className={`${
              striped && isOdd(idx) ? 'bmspt-bg-gray-50' : 'bmspt-bg-white'
            } bcl-table__tr`}
          >
            {columns.map(({ propName, render }) => (
              <Table.TD key={propName as string}>
                {render
                  ? render(item)
                  : renderTypeWithSaneDefaults(item[propName])}
              </Table.TD>
            ))}
          </Table.Row>
        ))}
      </tbody>
    </>
  );
};

/**
 * A sexy motherfucking table
 */
export const Table = <T,>({
  bordered = false,
  striped = false,
  tableData,
  children,
  className,
  ...props
}: TableProps<T> & React.HTMLProps<HTMLTableElement>) => {
  const [sortedBy, setSortedBy] = useState(tableData?.sortedBy);

  const sortedItems = useMemo(() => {
    if (sortedBy) {
      return tableData?.items.slice().sort((a, b) => {
        try {
          const { propName, order } = sortedBy;
          const aVal = a[propName];
          const bVal = b[propName];
          // @ts-ignore
          const type = aVal.constructor.name;
          const rank =
            type === 'String'
              ? // @ts-ignore-start
                aVal.localeCompare(bVal)
              : ['Date', 'Number'].includes(type)
              ? // @ts-ignore-start
                aVal - bVal
              : 0;

          return order === 'ASC' ? rank : -rank;
        } catch {
          return 0;
        }
      });
    }

    return tableData?.items;
  }, [sortedBy, tableData?.items]);

  return (
    <div className="bmspt-block bmspt-overflow-x-auto bmspt-w-full bcl-table__wrapper">
      <table
        className={classnames(
          'bmspt-min-w-full bmspt-divide-y bmspt-divide-gray-200 bcl-table__table',
          className
        )}
        {...props}
      >
        <TableContext.Provider value={{ bordered, striped }}>
          {tableData
            ? constructTableFromData(
                tableData,
                bordered,
                striped,
                sortedBy,
                setSortedBy,
                sortedItems
              )
            : children}
        </TableContext.Provider>
      </table>
    </div>
  );
};

/**
 * An optional container around the table
 */
Table.Container = TableContainer;

/**
 * The sexy body of a table
 */
Table.Body = TBody;

/**
 * The round head of a table
 */
Table.Head = THead;

/**
 * The row element
 */
Table.Row = Row;

/**
 * The th element
 */
Table.TH = HeaderCell;

/**
 * The td element
 */
Table.TD = DataCell;

Table.displayName = 'Table';
