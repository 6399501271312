import React, { Children, cloneElement, isValidElement } from 'react';
import { TableContext } from './Table';
import classnames from 'classnames';

export interface TBodyProps {
  children: React.ReactNode;
  className?: string;
}

const isOdd = (n: number) => n % 2;

export const TBody = ({
  children,
  className,
  ...props
}: TBodyProps & React.HTMLProps<HTMLTableSectionElement>) => {
  const { bordered, striped } = React.useContext(TableContext);

  return (
    <tbody
      className={classnames(
        'bcl-table__tbody',
        {
          'bmspt-bg-white bmspt-divide-gray-200 bmspt-divide-y': bordered,
        },
        className
      )}
      {...props}
    >
      {Children.map(children, (child, idx) =>
        isValidElement(child)
          ? cloneElement(child, {
              key: idx,
              ...child.props,
              className: classnames(
                striped && isOdd(idx) ? 'bmspt-bg-gray-50' : 'bmspt-bg-white',
                child.props.className
              ),
            })
          : child
      )}
    </tbody>
  );
};

TBody.displayName = 'Table.Body';
