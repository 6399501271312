import React, { Fragment, useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { renderIcon } from './utils/renderIcon';
import { ToastHeader } from './ToastHeader';
import { ToastDescription } from './ToastDescription';
import { ToastRegion } from './ToastRegion';

export interface ToastProps {
  children?: React.ReactNode;
  /**
   * Renders the toast with an icon.
   */
  icon?: 'error' | 'warning' | 'info' | 'success' | React.ReactNode;
  /**
   * Controls the visibility of the toast.
   */
  show: boolean;
  /**
   * A callback fired when the close button is clicked or autoHide is invoked.
   */
  onClose: () => void;
  /**
   * Dismisses the toast automatically after the given delay.
   */
  autoHide?: boolean;
  /**
   * Delays hiding the toast (ms). Used in conjunction with autoHide.
   */
  delay?: number;
  className?: string;
}

/**
 * Push notifications to your visitors with a toast, a lightweight and easily
 * customizable alert message. Toasts are lightweight notifications designed
 * to mimic the push notifications that have been popularized by mobile
 * and desktop operating systems.
 */
export const Toast = ({
  children,
  className,
  icon,
  show = true,
  onClose,
  autoHide = false,
  delay = 5000,
  ...props
}: ToastProps & React.HTMLProps<HTMLDivElement>) => {
  const timerId = useRef<NodeJS.Timeout>();

  const closeToast = useCallback(() => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (autoHide) {
      timerId.current = setTimeout(closeToast, delay);
    }
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, [autoHide, delay, closeToast]);

  return (
    <div className="bmspt-flex bmspt-flex-col bmspt-w-full bmspt-space-y-4 bmspt-items-end bcl-toast__container">
      <Transition
        show={show}
        as={Fragment}
        enter="bmspt-transform bmspt-ease-out bmspt-duration-300 bmspt-transition"
        enterFrom="bmspt-opacity-0 bmspt-translate-y-0 bmspt-translate-x-2"
        enterTo="bmspt-opacity-100 bmspt-translate-x-0"
        leave="bmspt-transition bmspt-ease-in bmspt-duration-100"
        leaveFrom="bmspt-opacity-100"
        leaveTo="bmspt-opacity-0"
        appear={true}
      >
        <div
          className={classnames(
            'bmspt-w-full bmspt-max-w-sm bmspt-overflow-hidden bmspt-bg-white bmspt-rounded-md bmspt-shadow-lg bmspt-pointer-events-auto bmspt-ring-1 bmspt-ring-black bmspt-ring-opacity-5',
            'bcl-toast',
            className
          )}
          {...props}
        >
          <div className="bmspt-p-4">
            <div className="bmspt-flex bmspt-items-start bcl-toast__content-wrapper">
              <div className="bmspt-flex-shrink-0 bcl-toast__icon">
                {renderIcon(icon)}
              </div>
              <div className="bmspt-ml-3 bmspt-w-0 bmspt-flex-1 bmspt-pt-0.5 bcl-toast__content">
                {children}
              </div>
              <div className="bmspt-flex bmspt-flex-shrink-0 bmspt-ml-4 bcl-toast__close">
                <button
                  className="bmspt-inline-flex bmspt-text-gray-400 bmspt-bg-white bmspt-rounded-md hover:bmspt-text-gray-500 focus:bmspt-outline-none focus:bmspt-ring-2 focus:bmspt-ring-offset-2 focus:bmspt-ring-indigo-500"
                  onClick={closeToast}
                >
                  <span className="bmspt-sr-only">Close</span>
                  <XIcon className="bmspt-w-5 bmspt-h-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

/**
 * A simple container with appropriate aria settings in which toasts shall go.
 */
Toast.Region = ToastRegion;

/**
 * The toast header.
 */
Toast.Header = ToastHeader;
/**
 * A description for the Toast.
 */
Toast.Description = ToastDescription;

Toast.displayName = 'Toast';
