import { format, formatDistanceToNow, subDays, isBefore } from 'date-fns';

export const displayDate = (date: Date) => {
  const cutOff = subDays(new Date(), 2);
  return isBefore(date, cutOff)
    ? format(date, 'dd MMMM yyyy, HH:mm')
    : `${formatDistanceToNow(date)} ago`;
};

export const displayExactDate = (date: Date) =>
  format(date, 'dd MMMM yyyy, HH:mm');
