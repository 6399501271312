const units = [
  { symbol: '', value: 1, maxLength: 8 }, // maximum 99999.99 => 8 chars
  { symbol: 'k', value: 1e3, maxLength: 6 }, // max. 999.99 k => 6 chars
  { symbol: 'M', value: 1e6, maxLength: 6 },
  { symbol: 'B', value: 1e9, maxLength: 6 },
];

// Round a number to 2 decimals
export const round = (num: number) => Math.round(num * 100) / 100;

export const formatNumWithSymbol = (
  num: number,
  shouldReturnInteger = false
) => {
  // Find the first unit in which the abbreviated form fits the specified length
  const unit =
    units.find(
      ({ value, maxLength }) => (num / value).toFixed(2).length <= maxLength
    ) || units[units.length - 1];

  return shouldReturnInteger
    ? Math.round(num / unit.value).toLocaleString() + unit.symbol
    : round(num / unit.value).toLocaleString() + unit.symbol;
};
