import { createPortal } from 'react-dom';
import React, { useContext, useEffect, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DropdownContext } from './Dropdown';
import classnames from 'classnames';
import { HTMLPropsWithoutAs } from '../utils/types';

export interface DropdownItemsProps {
  children?: React.ReactNode;
  /**
   * The element or component the Dropdown.Items should render as.
   */
  as?: React.ElementType<any> | keyof JSX.IntrinsicElements;
}

export const DropdownItems = ({
  children,
  className,
  as,
  ...props
}: DropdownItemsProps & HTMLPropsWithoutAs) => {
  const {
    isMenuOpen,
    setPopperElement,
    styles,
    attributes,
    update,
  } = useContext(DropdownContext);

  useEffect(() => {
    if (update) {
      update();
    }
  }, [isMenuOpen, update]);

  const enterAndLeaveStyles = {
    enter: 'bmspt-transition bmspt-ease-out bmspt-duration-100',
    enterFrom: 'bmspt-transform bmspt-opacity-0 bmspt-scale-95',
    enterTo: 'bmspt-transform bmspt-opacity-100 bmspt-scale-100',
    leave: 'bmspt-transition bmspt-ease-in bmspt-duration-75',
    leaveFrom: 'bmspt-transform bmspt-opacity-100 bmspt-scale-100',
    leaveTo: 'bmspt-transform bmspt-opacity-0 bmspt-scale-95',
  };

  return createPortal(
    <div
      className="bmspt-z-[9999] bcl-dropdown__items-wrapper"
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      <Transition as={Fragment} show={isMenuOpen} {...enterAndLeaveStyles}>
        <Menu.Items
          as={as}
          className={classnames(
            'bmspt-bg-white bmspt-min-w-full bmspt-max-h-96',
            'bmspt-overflow-y-auto bmspt-max-w-sm bmspt-rounded-md',
            'bmspt-shadow-lg bmspt-outline-none bmspt-overflow-x-hidden',
            'dark:bmspt-bg-gray-700',
            'bcl-dropdown__items',
            className
          )}
          static
          {...props}
        >
          {children}
        </Menu.Items>
      </Transition>
    </div>,
    document.body
  );
};

DropdownItems.displayName = 'Dropdown.Items';
